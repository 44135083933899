import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Phone from './phone';
import Email from './email';
import SocialNetworks from './social-networks';

const ContactInfo = ({
  title,
  phone,
  email,
  social_networks: socialNetworks,
}) => {
  const colDataClassNames = classNames(
    'contact-info__col-data',
    'contact-info__col-data--full',
  );
  const infoBlockClassNames = classNames(
    'contact-info__block',
    'remark-data',
  );

  if (!socialNetworks && (!phone || !phone.formatted) && (!email || !email.value)) {
    return null;
  }

  return (
    <div className="contact-info">
      <div className={colDataClassNames}>
        <h2 className="contact-info__title">{title}</h2>
        <div className={infoBlockClassNames}>
          { phone ? <Phone phone={phone} /> : null }
          { email ? <Email email={email} /> : null }
        </div>
        {
          (socialNetworks && socialNetworks.value.length > 0)
            ? <SocialNetworks socialNetworks={socialNetworks} /> : null
        }
      </div>
    </div>
  );
};

ContactInfo.propTypes = {
  title: PropTypes.string,
  phone: PropTypes.shape({
    title: PropTypes.string,
    raw: PropTypes.string,
    formatted: PropTypes.string,
  }),
  email: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }),
  social_networks: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      account: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
};

export default ContactInfo;
