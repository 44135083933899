import React from 'react';
import PropTypes from 'prop-types';

const Phone = ({ phone }) => (
  <div>
    <label htmlFor="phone" className="contact-info__label">{phone.title}</label>
    <div id="phone" className="contact-info__data">
      <a href={`tel:${phone.raw}`} rel="nofollow">{phone.formatted}</a>
    </div>
  </div>
);

Phone.propTypes = {
  phone: PropTypes.shape({
    title: PropTypes.string,
    raw: PropTypes.string,
    formatted: PropTypes.string,
  }).isRequired,
};

export default Phone;
